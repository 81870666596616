import React from 'react';
import Image from 'gatsby-image';
import { graphql, useStaticQuery  } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
// import headshot from '../images/headshot2.jpg';

const query = graphql`
  {
    file(relativePath: {eq: "headshot2.jpg"}) {
      childImageSharp {
        fluid(quality: 100) {
			...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const AboutPage = () => {
	const { file: { childImageSharp: { fluid } } } = useStaticQuery(query);
	return (
		<Layout>
			<SEO keywords={[ `Website Development`, `Website Design`, `Web Application`, `Data Analytics`, `Data Visualization`, `Freelance`, `Colin Willer` ]} title="About" />

			<section className="lg:mb-12">
				<h1 className="font-bold text-gray-900 text-center my-2 text-2xl lg:text-3xl xl:text-5xl xl:my-4">
					More About Me
				</h1>
				<div className="container mx-auto flex flex-col md:flex-row items-start ">
					{/* Left Column */}
					<div className="flex flex-col w-full justify-center items-start text-center py-6 md:text-left lg:w-1/2">
						{/* <img
							src={headshot}
							alt="Colin Willer Headshot Image"
							className="w-11/12 mx-auto h-auto max-w-full md:w-full xl:max-w-lg"
						/> */}
						<Image fluid={fluid} alt="Colin Willer Headshot Image" className="w-11/12 mx-auto h-auto max-w-full md:w-full xl:max-w-lg" />
					</div>
					{/* Right Column */}
					<div className="w-full py-6 lg:w-1/2 lg:py-4 xl:py-16">
						<p className="text-xs text-center text-gray-900 mb-2 px-4 pb-2 md:text-left md:px-0 md:pl-6 lg:text-lg lg:pb-1 xl:text-xl xl:pl-0">
							<span className="text-wtblue font-bold text-xl md:text-sm lg:text-xl xl:text-2xl">
								Where this all started
							</span>
							<span className="text-wtblue font-bold text-xl hidden md:inline-block md:text-sm lg:text-xl xl:text-2xl">
								...{'  '}
							</span>
							<br className="md:hidden" />
							{'  '}This all started after I came to the realization that my true passion was helping
							people utilize new technologies and data to grow their businesses. Through my professional
							experience in building web applications and helping people better utilize data I realized it
							could give me the perfect combination of my technical skills and my business acumen. Now I
							have the freedom to do what I love while helping you expand your organization.
						</p>
						<p className="text-xs text-center text-gray-900 mb-2 px-4 pb-2 md:text-left md:px-0 md:pl-6 lg:text-lg lg:pb-1 xl:text-xl xl:pl-0">
							<span className="text-wtblue font-bold text-xl md:text-sm lg:text-xl xl:text-2xl">
								Professional Background
							</span>
							<span className="text-wtblue font-bold text-xl hidden md:inline-block md:text-sm lg:text-xl xl:text-2xl">
								...{'  '}
							</span>
							<br className="md:hidden" />
							{'  '}I’ve worked for companies both large and small in a variety of roles ranging from
							developing business applications that leverage the Internet of Things, building dashboards
							to visualize data, project management, day to day supply chain management, and even
							financial modeling.
						</p>
						<p className="text-xs text-center text-gray-900 mb-2 px-4 pb-2 md:text-left md:px-0 md:pl-6 lg:text-lg lg:pb-1 xl:text-xl xl:pl-0">
							<span className="text-wtblue font-bold text-xl md:text-sm lg:text-xl xl:text-2xl">
								My Process
							</span>
							<span className="text-wtblue font-bold text-xl hidden md:inline-block md:text-sm lg:text-xl xl:text-2xl">
								...{'  '}
							</span>
							<br className="md:hidden" />
							{'  '}I’m here to help you bring your ideas to life. I create every solution specifically
							for each customer through an iterative process until we come to a solution that satisfies
							your business. Contact me to learn more about what I can do to help your business needs.
						</p>
						{/* <p className="text-xs text-center text-gray-900 mb-2 px-4 pb-2 md:text-left md:px-0 md:pl-6 lg:text-lg lg:pb-1 xl:text-xl xl:pl-0">
							<span className="text-wtblue font-bold text-xl md:text-sm lg:text-xl xl:text-2xl">
								Education
							</span>
							<span className="text-wtblue font-bold text-xl hidden md:inline-block md:text-sm lg:text-xl xl:text-2xl">
								...{'  '}
							</span>
							<br className="md:hidden" />
							{'  '}I graduated in 2019 from the University of Minnesota, go gophers, with a degree in
							business. Specifically, studying supply chain management, finance, and business analytics.
						</p> */}
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default AboutPage;
